import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class ServiceHttpService {
  constructor(private readonly http: HttpClient) {}

  updateSuspendedAt(companyId: number, serviceId: number, suspendedAt: string): Observable<void> {
    return this.http.put<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/services/${serviceId}/suspended-at`,
      { suspendedAt },
    );
  }
}
