import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppConfig } from '@dougs/core/config';
import { ServicesState } from '@dougs/revenue/services/dto';
import { ServicesStateHttpCacheService } from '../cache/services-state-cache.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesStateHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly servicesStateHttpCacheService: ServicesStateHttpCacheService,
  ) {}

  getCompanyServicesState(companyId: number, force?: boolean): Observable<ServicesState> {
    let servicesState$ = this.servicesStateHttpCacheService.getValue(companyId);

    if (!servicesState$ || force) {
      servicesState$ = this.http
        .get<ServicesState>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/state`)
        .pipe(shareReplay(1));

      this.servicesStateHttpCacheService.setValue(servicesState$, companyId);
    }

    return servicesState$;
  }

  startAccountingTrial(companyId: number): Observable<ServicesState> {
    return this.http.post<ServicesState>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/services/start-accounting-trial`,
      {},
    );
  }

  startCreationService(companyId: number): Observable<ServicesState> {
    return this.http.post<ServicesState>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/services/creation/start`,
      {},
    );
  }

  startInvoicingService(companyId: number): Observable<ServicesState> {
    return this.http.get<ServicesState>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/services/invoicing/start`,
    );
  }
}
