import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { ServicesState } from '@dougs/revenue/services/dto';
import { ServicesStateHttpService } from '../http';

interface CompanyServicesState {
  services: ServicesState;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyServicesStateService extends StateService<CompanyServicesState> {
  services$: Observable<ServicesState> = this.select<ServicesState>((state) => state.services);

  constructor(
    private readonly logger: LoggerService,
    private readonly servicesStateHttpService: ServicesStateHttpService,
  ) {
    super();
  }

  get services(): ServicesState {
    return this.state?.services;
  }

  setServices(services: ServicesState): void {
    this.setState({ services });
  }

  async refreshServices(companyId: number, force?: boolean): Promise<void> {
    try {
      if (companyId) {
        const services: ServicesState = await lastValueFrom(
          this.servicesStateHttpService.getCompanyServicesState(companyId, force),
        );
        this.setServices(services);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async startAccountingTrial(companyId: number): Promise<void> {
    try {
      const services = await lastValueFrom(this.servicesStateHttpService.startAccountingTrial(companyId));
      this.setServices(services);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async startCreationService(companyId: number): Promise<boolean> {
    try {
      await lastValueFrom(this.servicesStateHttpService.startCreationService(companyId));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async startInvoicingService(companyId: number): Promise<boolean> {
    try {
      await lastValueFrom(this.servicesStateHttpService.startInvoicingService(companyId));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
