import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { InvoicingSettings, Offer, PricingSettings, Service, ServiceOffer } from '@dougs/revenue/services/dto';
import { OfferHttpService, ServiceHttpService } from '../http';

interface ActiveOfferState {
  serviceOffer?: ServiceOffer;
  pricingSettings?: PricingSettings;
  invoicingSettings?: InvoicingSettings;
  service?: Service;
}

@Injectable({
  providedIn: 'root',
})
export class ActiveOfferStateService extends StateService<ActiveOfferState> {
  constructor(
    private readonly offerHttpService: OfferHttpService,
    private readonly logger: LoggerService,
    private readonly serviceHttpService: ServiceHttpService,
  ) {
    super();
  }

  async refreshActiveOffer(companyId: number): Promise<void> {
    try {
      const offer: Offer = await lastValueFrom(this.offerHttpService.getActiveOffer(companyId));

      if (!!offer && !!offer.serviceOffers && offer.serviceOffers.length > 0) {
        const serviceOffer: ServiceOffer = offer.serviceOffers[0];

        this.setActiveOfferState({
          serviceOffer,
          pricingSettings: serviceOffer.pricingSettings,
          invoicingSettings: serviceOffer.invoicingSettings,
          service: serviceOffer.service,
        });
      } else {
        this.setActiveOfferState({});
      }
    } catch (e) {
      this.setActiveOfferState({});
      this.logger.error('OfferStateService.refreshActiveOffer: aucune offre active');
    }
  }

  setActiveOfferState(activeOfferState: ActiveOfferState): void {
    this.setFullState(activeOfferState);
  }

  async updateSuspendedAt(suspendedAt: string | undefined): Promise<void> {
    if (!this.state?.service || suspendedAt === undefined) {
      return;
    }

    try {
      await lastValueFrom(
        this.serviceHttpService.updateSuspendedAt(this.state.service.companyId, this.state.service.id, suspendedAt),
      );

      this.setActiveOfferState({
        ...this.state,
        service: { ...this.state.service, suspendedAt },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
