import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Offer } from '@dougs/revenue/services/dto';

@Injectable({
  providedIn: 'root',
})
export class OfferHttpService {
  constructor(private readonly http: HttpClient) {}

  getActiveOffer(companyId: number): Observable<Offer> {
    return this.http.get<Offer>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/offers/active-offer`);
  }
}
